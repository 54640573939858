<template>
	<div class="App handleTextHierachieOutlining" lang="de">

		<MhDevGrid
			:doLog="false"
			:showOnHosts="[
				'localhost',
				'zahnaerzte-luci-clausner.local',
				'dev.zahnaerzte-luci-clausner.de'
			]"
			:show="_.get( currentUser, 'display_name' ) ? true : undefined"
			:columns="12"
			:color="'cyan'"
			:gridGap="'var(--app-content-gridGap)'"
			:maxWidth="'var(--app-content-maxWidth)'"
			:hPadding="'var(--app-content-hPadding)'"
			:opacity="0.35"
			:toggleKey="'g'"
			:forceVisibleOnMount="true"
		></MhDevGrid>

		<MhRouterViewWrapper />

		<MhDelegateLinks
			:doLog="false"
		></MhDelegateLinks>

		<MhTagsOutlining
			:doLog="true"
			:color="'rgba( 255, 0, 175, 0.5 )'"
			:selectors="[
				'[showborders4] .view'
			]"
		></MhTagsOutlining>

		<MhHeadInfos
			:doLog="false"
			:showDebugPanel="false"
			:ignoreViews="[
				'DevView',
			]"
		></MhHeadInfos>

		<!--
		<MhDevPanel
			v-if="isReady"
			:doLog="false"
			:quickLinks="[
				{ label : 'Home', href : '/' },
				{ label : 'DevView', href : '/dev/' },
			]"
			:showOnHosts="['localhost', 'zahnaerzte-luci-clausner.local']"
			:nonce="_.get( currentUser, 'wp_rest_nonce' )"
			:show="_.get( currentUser, 'display_name' ) ? true : undefined"
		></MhDevPanel>
		-->

		<MhDevInfos
			:doLog="false"
			:showOnHosts="[
				'localhost',
				'zahnaerzte-luci-clausner.local',
				'dev.zahnaerzte-luci-clausner.de',
			]"
			:show="_.get( currentUser, 'display_name' ) ? true : undefined"
		></MhDevInfos>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import restHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js'
	import MhPreviewLinkHandler from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhPreviewLinkHandler/v1/MhPreviewLinkHandler.js'
	import MhDevInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevInfos/v2/MhDevInfos.vue'
	import MhHeadInfos from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhHeadInfos/v1/MhHeadInfos.vue'
	import MhDelegateLinks from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDelegateLinks/v5/MhDelegateLinks.vue'
	import MhRouterViewWrapper from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterViewWrapper.vue'
	import MhDevPanel from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevPanel/v1/MhDevPanel.vue'
	import MhDevGrid from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhDevGrid/v5/MhDevGrid.vue'
	import MhStickyHeader from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhStickyHeader/v3/MhStickyHeader.vue'
	import MhTagsOutlining from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhTagsOutlining/v1/MhTagsOutlining.vue'

	export default {
		name: 'App',
		components: {
			MhHeadInfos,
			MhRouterViewWrapper,
			MhDevInfos,
			MhDevPanel,
			MhDelegateLinks,
			MhDevGrid,
			MhStickyHeader,
			MhTagsOutlining,
		},
		directives: {},
		mixins: [
			restHandler,
			MhPreviewLinkHandler
		],
		data() {
			return {
				isReady : false,
				acfOptions : null,
				appInfos : null,
				currentUser : null,
			}
		},
		computed: {},
		methods: {
			setCssVars(){
				const root                = document.documentElement
				      root.style.overflow = 'auto'
				      root.style.height   = '101vh'
				const scrollbarWidth      = window.innerWidth - root.offsetWidth + 'px'
				      root.style.overflow = ''
				      root.style.height   = ''

				root.style.setProperty('--app-scrollbar-width', scrollbarWidth)
			},
			getLinkWithoutHostname( url ){
				let theReturn = ''

				try {
					let _url = new URL( url )
					theReturn = _url.pathname
				}
				catch(e) {
					//
				}

				return theReturn
			},
			fetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/acfOptions',
					params : {},
					callback : (response) => {
						this.acfOptions = response.data.result

						runCallback()
					},
				})
				/*
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/appInfos',
					params : {},
					callback : (response) => {
						this.appInfos = response.data

						runCallback()
					},
				})
				*/
			},
		},
		mounted(){
			this.setCssVars()

			this.fetchAll(()=>{
				//console.log( this.$options.name, '• fetchAll() • finalCallback()')

				this.isReady = true
			})

			//console.log('this:', this)

			/*
			this.$router.afterEach((to, from) => {
				console.group('this.$router.afterEach(to, from)')
				console.log('	from', from)
				console.log('	to  ', to)
				console.groupEnd()
			})
			*/

			// fetch example
			/*
			this.restHandler__fetch({
				action : 'GET',
				route : '/wp-json/mh/v1/acfOptions',
				params : {},
				callback : (response) => {
					this.acfOptions = response.data.result

					// hier wird aus allen introImages eins zufällig ausgewählt
					this.acfOptions.introImage = this._.sample( this.acfOptions.introImages )

					// um das layout handeln zu können sind die beiden
					// ersten menüpunkte, news + info, fest ins system integriert
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "Info",
						title__en: "Info",
						type: "toggleSecondRow",
						page: false
					})
					this.acfOptions.mainMenu__row1.unshift({
						title__de: "News",
						title__en: "News",
						type: "homeView",
						page: false
					})

					this.$set(this.acfOptions, 'translations2', this.translations )
				},
			})
			*/

			// after route change: scroll App__body to top
			/*
				const AppBody = document.querySelector('.App__body')
				this.$router.afterEach( (to, from) => {
					AppBody.scrollTo(0, 0)
				})
			*/

			// EventBus example
			/*
				EventBus.$on('AppHeaderResized', elmHeight => {
					console.log( elmHeight )
				})
			*/
		}
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import "@/less/atoms.less";

	:root { // vars
		--app-scrollbar-width: 0px; // wird via js von setCssVars() gesetzt
		--app-content-hPadding: 3.5rem;

		--app-content-maxWidth: 960px;
		--app-content-maxWidth: 1100px;
		--app-content-gridGap: 1rem;

		--color-app-background: white;
		--color-app-footer-background: #193850;
		--color-app-footer-color: #FFFDFD;
		--color-content-background: fade( white, 35 );
		--color-text: #193850;
		--color-link: fade( blue, 90 );

		@media @mq[sm] {
			--app-content-gridGap: 12px;
		}
		@media @mq[sm] {
			--app-content-hPadding: 16px;
		}
	}

	html {
		color: var(--color-text);
		font-family: "DIN W03 Regular";
		font-size: 24px;
		line-height: 1.5em;
	}
	strong, b {
		.font--bold;
	}
	button {
		cursor: pointer;
	}
	h1, h2, h3, h4, h5, h6 {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
		margin: 0;
 		overflow-wrap: break-word;
	}
	p {
		overflow-wrap: break-word;
	}
	a {
		cursor: pointer;
		color: var(--color-link);
		text-decoration: none;
	}
	pre {
		@color: black;

		position: relative;
		padding: 0.5em;
		width: 100%;
		min-height: 2em;
		&[maxheight] { max-height: 300px; }

		margin-bottom: 0.5em;
		&:first-of-type { margin-top: 1em; }
		&:last-of-type { margin-bottom: 1em; }
		&:first-child { margin-top: 0em; }

		tab-size: 4;
		white-space: pre;

		outline: 1px solid fade(@color, 25);
		background-color: fade(@color, 5);
		overflow: auto;

		// label
		&[data-name]:after,
		&[name]:after {
			position: absolute;
			top: 0; right: 0;
			background-color: fade(@color, 15);
			font-size: 13px;
			line-height: 1em;
			font-family: sans-serif;
			color: fade(@color, 75);
			padding: 0.25em 0.5em;
			content: attr(data-name) attr(name);
			font-family: inherit;
		}
	}
	hr {
		margin: 0.5em 0;
		border: none;
		border-top: 1px solid;
		opacity: 0.25;
	}
	ul, ol {
		padding-left: 1rem;
	}

	.view {
		&__inner {
			//background-color: var(--color-content-background);
			//padding: var(--app-content-hPadding);
			//max-width: var(--app-content-maxWidth);
			//margin: 0 auto;
		}
	}
	.toggleIndicator {
		//outline: 1px solid red;

		position: relative;
		width: 1.3em;
		height: 1.2em;
		display: block;
		flex-grow: 0;
		flex-shrink: 0;
		user-select: none;

		&::before {
			transition: all 0.2s ease;
			border-top: 0.25em solid transparent;
			border-bottom: 0.25em solid transparent;
			display: block;
			width: inherit;
			height: inherit;
			background-image: url('./assets/imgs/arrow--down.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			content: "";
		}

		&--isActive { &::before { transform: rotate(-180deg); } }
		&--isInactive {}
	}
	.richText {
		//color: green;

		a {
			transition: all 0.1s ease;
			text-decoration: underline;
			text-underline-offset: 0.25em;
			//text-decoration-style: dotted;
			color: inherit;
			//opacity: 0.65;
		}
		a:hover {
			color: #E48900;
			opacity: 1;
			//text-decoration-style: solid;
		}
		ul, ol {
			padding-left: 1.2em;
		}
	}
	.hSpace--app {
		padding-left: var(--app-content-hPadding);
		padding-right: var(--app-content-hPadding);
	}
	.contentModule {
		[showBorders2] & {
			.label( attr(data-debug-label), red );
			outline: 1px solid red;
		}
		position: relative;
		max-width: 100vw;
	}

	.App {
		background-color: var(--color-app-background);
		min-height: 100vh;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
