<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhSliderDots
			:count="8"
			:activeIndex="0"
		/>

	2022-01-24	init

-->

<template>
	<ul class="MhSliderDots" :class="elmClasses" v-if="_count">
		<a class="MhSliderDots__dot"
		   :class="activeIndex === index ? 'MhSliderDots__dot--isActive' : 'MhSliderDots__dot--isInactive'"
		   v-for="(dot, index) in _count" :key="index"
		   @click="onClick( index )">
			<span class="MhSliderDots__dotInner" v-html="index"></span>
		</a>
	</ul>
</template>

<script>
	export default {
		name: 'MhSliderDots',
		components: {},
		mixins: [],
		props: {
			count : {
				type     : [Number],
				default  : 0,
				required : false,
			},
			activeIndex : {
				type     : [Number],
				default  : 0,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			activeIndex: {
				handler: function( to, from ) {
					//console.log('activeIndex:', this.activeIndex)
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--textColor-' + this.textColor )
				classes.push( this.$options.name + '--bgColor-' + this.bgColor )

				return classes
			},
			_count(){
				let count = Math.ceil( this.count )
				return count
			},
		},
		methods: {
			onClick( index, doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onClick()' )
					console.log('index:', index)
					console.groupEnd()
				}

				this.$emit('goToSlide', index)
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";

	*, :after, :before {
		margin: 0; padding: 0;
		box-sizing: border-box;
	}
	.hideIfEmpty { &:empty { display: none; } }

	.MhSliderDots { // debug
		[showBorders1] & {
			background-color: fade( red, 20 );

			.MhSliderDots__dot { background-color: fade( red, 15 ); }
			.MhSliderDots__dotInner { background-color: fade( red, 50 ); }
		}
	}
	.MhSliderDots { // vars
		--transitionDuration: .25s;
		--dot-height: 0.5em;
		--dot-margin: 1.5em 0.25em;
		/*
		--dot-active-border-color : lightblue;
		--dot-inactive-border-color : fade( black, 75 );
		--dot-hover-background-color : fade( black, 85 );
		*/
	}
	.MhSliderDots { // layout
		display: flex;
		justify-content: center;
	}
	.MhSliderDots { // styling
		padding: 0;

		@swatches {
			lightBlue : lightblue;
			darkGrey : fade( black, 75 );
		}
		&__dot {
			transition: all var(--transitionDuration) ease;
			display: flex;
			padding: var(--dot-margin);
			cursor: pointer;
		}
		&__dotInner {
			transition: inherit;
			height: var(--dot-height);
			width: var(--dot-height);
			border-radius: 50%;
			overflow: hidden;
			text-indent: 10em;
		}
		//&__dot--isActive &__dotInner { border: calc( var(--dot-height) * 0.5 ) solid var(--dot-active-border-color); }
		//&__dot--isInactive &__dotInner { border: 1px solid var(--dot-inactive-border-color); }
		//&__dot:hover &__dotInner { background-color: var(--dot-hover-background-color); }
		&__dot--isActive &__dotInner {
			background-color: #2A4960;
		}
		&__dot--isInactive &__dotInner {
			background-color: #BEC7CE;

		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
