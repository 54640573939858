<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaRichText></ZaRichText>

	2021-12-15	init

-->

<template>
	<div class="ZaRichText" :class="elmClasses" data-debug-label="ZaRichText">
		<div class="ZaRichText__inner">
			<div class="ZaRichText__text richText" v-html="text"></div>
			<!--
			<pre name="$props">{{$props}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'ZaRichText',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaRichText { // debug
		[showBorders3] &__text > * {
			outline: 1px dotted magenta;
		}
	}
	.ZaRichText { // vars
	}
	.ZaRichText { // layout
		&__inner {
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}
	}
	.ZaRichText { // styling
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: 24px;
		line-height: 1.5em;

		ul, ol { padding-left: 1.65rem; }
		li { padding-left: 0.5rem; }

		@media @mq[dt] {
			h1 { font-size: 74px; line-height: 1.2em; }
			h2 { font-size: 46px; line-height: 1.2em; }
			h3 { font-size: 28px; line-height: 1.35em; }
		}
		@media @mq[md] {
			h1 { font-size: 56px; line-height: 1.3em; }
			h2 { font-size: 28px; line-height: 1.35em; }
			h3 { font-size: 28px; line-height: 1.35em; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
