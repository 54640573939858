<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaServices></ZaServices>

	2022-01-20	init

-->

<template>
	<div class="ZaServices" :class="elmClasses" data-debug-label="ZaServices">
		<div class="ZaServices__inner">
			<ZaHeadline :title="title"></ZaHeadline>
			<div class="ZaServices__services">
				<div class="ZaServices__service"
					v-for="(item, index) in services" :key="index">
					<div class="ZaServices__serviceTitleWrapper" @click="setToggleState( index )" :role="$root.isBelowDt ? 'button' : ''">
						<h5 class="ZaServices__serviceTitle" v-html="item.title"></h5>
						<div class="ZaServices__toggleIndicator toggleIndicator" :class="getToggleState( index ) ? 'toggleIndicator--isActive' : 'toggleIndicator--isInactive'">&nbsp;</div>
					</div>
					<div class="ZaServices__serviceText richText" v-show-slide="getToggleState( index )" v-html="item.text"></div>
				</div>
			</div>
			<!--
			<pre name="$props">{{$props}}</pre>
			<pre name="toggleStates">{{toggleStates}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import ZaHeadline from '@/components/ZaHeadline.vue'

	export default {
		name: 'ZaServices',
		components: {
			ZaHeadline,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
			services: {
				type     : [Array],
				default  : ()=>{ return [] },
				required : false,
			},
		},
		data(){
			return {
				toggleStates : [],
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			setToggleState( index, doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• setToggleState( index)' )
					console.log('index:', index)
					console.groupEnd()
				}

				if( this.$root.isBelowDt && this.toggleStates.includes( index ) ){
					const itemIndex = this.toggleStates.indexOf( index )
					this.toggleStates.splice( itemIndex, 1 )
				}
				else if( this.$root.isBelowDt ){
					this.toggleStates.push( index )
				}
			},
			getToggleState( index, doLog = false ){
				const state = this.toggleStates.includes( index ) || !this.$root.isBelowDt

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• getToggleState( index)' )
					console.log('index:', index)
					console.log('-----')
					console.log('state:', state)
					console.groupEnd()
				}

				return state
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaServices { // debug
		[showBorders3] &__service {
			outline: 1px dotted red;
		}
		[showBorders3] &__service > * {
			outline: 1px dotted magenta;
		}
	}
	.ZaServices { // vars
		--background: #F0ECD3;
	}
	.ZaServices { // layout
		&__inner {
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}

		&__services {}
		&__serviceTitleWrapper { display: flex; }
		&__serviceTitle { flex-grow: 1; }

		@media @mq[dt] {
			&__services {
				.grid;
				.grid--colGap;
				.grid--rowGap;
				.grid--cols-12;
			}
			&__service:nth-child( odd ) { grid-column: 2 / span 5; }
			&__service:nth-child( even ) { grid-column: 8 / span 5; }
			&__toggleIndicator { display: none; }
		}
	}
	.ZaServices { // styling
		background-color: var(--background);

		@media @mq[dt] {
			padding-top: 0rem;
			padding-bottom: 2rem;

			&__services { padding-top: .25em; }
			&__serviceTitle { font-size: 28px; line-height: 1.35em; }
		}
		@media @mq[md] {
			&__toggleIndicator { margin-top: 0.15em; }
			&__service + &__service { padding-top: 0.5rem; border-top: 1px solid; }
			&__serviceTitleWrapper { padding-bottom: 0.5rem; cursor: pointer; }
			&__serviceTitle { font-weight: bold; }
			&__serviceText > *:last-child { padding-bottom: 1.5em; }

		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
