<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaHeadline></ZaHeadline>

	2021-12-15	init

-->

<template>
	<div class="ZaHeadline" :class="elmClasses" data-debug-label="ZaHeadline">
		<div class="ZaHeadline__inner">
			<h2 class="ZaHeadline__title" v-html="title"></h2>
			<!--
			<pre name="$props">{{$props}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'ZaHeadline',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaHeadline { // debug
		[showBorders3] & {}
	}
	.ZaHeadline { // vars
	}
	.ZaHeadline { // layout
		&__inner {
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}
	}
	.ZaHeadline { // styling
		&__title { font-size: 46px; line-height: 1.2em; }

		@media @mq[dt] {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
		@media @mq[md] {
			padding-top: 1rem;
			padding-bottom: 1rem;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
