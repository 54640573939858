<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaSlider></ZaSlider>

	2022-01-20	init

-->

<template>
	<div class="ZaSlider" :class="elmClasses" data-debug-label="ZaSlider">
		<div class="ZaSlider__inner">
			<vueper-slides
				ref="myVueperSlides"
				:visible-slides="sliderVisibleSlides"
				:arrows="false"
				:bullets="false"
				:dragging-distance="50"
				:fixedHeight="sliderHeight"
				:infinite="false"
				:slideMultiple="true"
				@ready="onSliderReady"
				@slide="onSlide"
			>
				<vueper-slide v-for="(slide, index) in slides" :key="index">
					<template v-slot:content>
						<!-- desktop -->
						<div class="ZaSlider__slide" v-if="!$root.isBelowDt">
							<MhImage class="ZaSlider__slideImage"
								:imageObject="slide.dtImage"
								:mode="'cover'"
							></MhImage>
							<div class="ZaSlider__slideBu" v-html="slide.bu"></div>
						</div>
						<!-- phone -->
						<div class="ZaSlider__slide" v-else>
							<MhImage class="ZaSlider__slideImage"
								:imageObject="slide.smImage"
								:mode="'cover'"
							></MhImage>
						</div>
					</template>
				</vueper-slide>
			</vueper-slides>

			<MhSliderDots
				v-if="slides.length / sliderVisibleSlides > 1"
				:count="slides.length / sliderVisibleSlides"
				:activeIndex="currentActiveIndex / sliderVisibleSlides"
				@goToSlide="$refs.myVueperSlides.goToSlide( $event * sliderVisibleSlides )"
			></MhSliderDots>

			<div class="ZaSlider__mobileSlideBu hSpace hSpace--app"
				v-if="$root.isBelowDt"
				v-html="currentSlideBu"
			></div>

			<!--
			<pre name="$props">{{$props}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import { VueperSlides, VueperSlide } from 'vueperslides'
	import 'vueperslides/dist/vueperslides.css'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'
	import MhSliderDots from '@/components/MhVueperSlides/v2/MhSliderDots.vue'

	export default {
		name: 'ZaSlider',
		components: {
			VueperSlides,
			VueperSlide,
			MhImage,
			MhSliderDots,
		},
		mixins: [],
		props: {
			dtHeight: {
				type     : [String, Boolean],
				default  : '400',
				required : false,
			},
			smHeight: {
				type     : [String, Boolean],
				default  : '200',
				required : false,
			},
			slides: {
				type     : [Array, Boolean],
				default  : ()=>{ return [] },
				required : false,
			},
		},
		data(){
			return {
				currentActiveIndex : 0,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			sliderHeight(){
				return this.$root.isBelowDt ? this.smHeight + 'px' : this.dtHeight + 'px'
			},
			sliderVisibleSlides(){
				return this.$root.isBelowDt ? 1 : 2
			},
			currentSlideBu(){
				//return this.slides[this.currentActiveIndex].bu
				const bu = this._.get( this.slides, '['+this.currentActiveIndex+'].bu' )
				return bu ? bu + '<br/>' : ''
			},
		},
		methods: {
			onSliderReady( e, doLog = false ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onSliderReady()' )
					console.log('e:', e)
					console.groupEnd()
				}
				this.$nextTick(()=>{
					//this.onAfterResize()
				})
			},
			onSlide( e, doLog = false ){
				const index = e.currentSlide.index

				this.currentActiveIndex = index

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onSlide()' )
					console.log('index:', index)
					console.groupEnd()
				}
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaSlider { // debug
		[showBorders3] & {
			.vueperslides {
				outline: 1px solid green;
			}
			.vueperslide {
				outline: 1px solid blue;
			}
			&__mobileSlideBu {
				background-color: fade( red, 20 );
			}
			&__slideBu {
				background-color: fade( yellow, 35 );
			}
		}
	}
	.ZaSlider { // vars
	}
	.ZaSlider { // layout
		&__inner {
			//max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}
		.vueperslides--fixed-height.vueperslides--bullets-outside { margin: 0; }
		.vueperslide { position: relative; }

		&__slide {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		&__slideImage {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		&__slideBu {
			position: absolute;
			top: 0; left: 50%; right: 0; bottom: 0em;
		}
		&__mobileSlideBu {
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
			&:empty { display: none; }
		}

		@media @mq[dt] {}
		@media @mq[md] {}
	}
	.ZaSlider { // styling
		.vueperslides__parallax-wrapper::before,
		.vueperslides__parallax-wrapper::after {
			box-shadow: none !important;
		}

		&__slideBu {
			padding-top: 5em;
			padding-right: 1em;
		}
		&__mobileSlideBu {}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
