<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaContentModules></ZaContentModules>

	2021-12-15	init

-->

<template>
	<div class="ZaContentModules" :class="elmClasses">
		<div class="ZaContentModules__inner">
			<div class="ZaContentModules__contentModule contentModule" :class="getComponentClasses( cm )"
				 v-for="(cm, index) in contentModules"
				 :key="index" :is="getComponentName( cm )"
				 v-bind="getComponentBind( cm )"
			></div>
			<!--
			<pre :data-name="'contentModules • ' + contentModules.length">{{contentModules}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import { upperFirst } from 'lodash'
	import EventBus from '@/helper/EventBus.js'
	import ZaPageHeader from '@/components/ZaPageHeader.vue'
	import ZaCoronaMessage from '@/components/ZaCoronaMessage.vue'
	import ZaHeadline from '@/components/ZaHeadline.vue'
	import ZaRichText from '@/components/ZaRichText.vue'
	import ZaServices from '@/components/ZaServices.vue'
	import ZaSlider from '@/components/ZaSlider.vue'
	import ZaSpacer from '@/components/ZaSpacer.vue'

	export default {
		name: 'ZaContentModules',
		components: {
			ZaPageHeader,
			ZaCoronaMessage,
			ZaHeadline,
			ZaRichText,
			ZaServices,
			ZaSlider,
			ZaSpacer,
		},
		mixins: [],
		props: {
			contentModules: {
				type     : [Array, Boolean],
				default  : ()=>{ return [] },
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			getComponentName( cmName, doLog = false ){
				let returnName = ''

				returnName = 'Za' + upperFirst( cmName.acf_fc_layout )

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• cmName()' )
					console.log('returnName:', returnName)
					console.groupEnd()
				}

				return returnName
			},
			getComponentBind( cm ){
				return cm
			},
			getComponentClasses( cm ){
				return this.getComponentName( cm ) === 'ZaSlider' ? '' : 'hSpace hSpace--app'
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaContentModules { // debug
		[showBorders3] & {
			outline: 1px solid red;
		}
	}
	.ZaContentModules { // vars
	}
	.ZaContentModules { // layout
	}
	.ZaContentModules { // styling
		padding-bottom: 5rem;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
