<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaPageHeader></ZaPageHeader>

	2022-01-17	init

-->

<template>
	<div class="ZaPageHeader" :class="elmClasses" data-debug-label="ZaPageHeader">
		<div class="ZaPageHeader__inner">
			<h5 class="ZaPageHeader__brand">
				<a href="/">
					Zahnärzte <br/>
					Luci & Clausner
				</a>
			</h5>
			<div class="ZaPageHeader__sticker"></div>
			<h1 class="ZaPageHeader__title">
				<span class="ZaPageHeader__titleInner hyphenate" v-html="title"></span>
			</h1>
			<ZaBookingLink
				v-if="type === 'home'"
				:buttonText="buttonText"
				:buttonLink="buttonLink"
			></ZaBookingLink>
			<!--
			<pre name="$props">{{$props}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import ZaBookingLink from '@/components/ZaBookingLink.vue'

	export default {
		name: 'ZaPageHeader',
		components: {
			ZaBookingLink,
		},
		mixins: [],
		props: {
			type: {
				type     : [String],
				default  : 'page',
				required : false,
			},
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
			buttonText: {
				type     : [String],
				default  : '',
				required : false,
			},
			buttonLink: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--type-' + this.type )

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaPageHeader { // debug
		[showBorders3] &__inner > * {
			outline: 1px dotted magenta;
		}
	}
	.ZaPageHeader { // vars
	}
	.ZaPageHeader { // layout
		&__inner {
			position: relative;
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
			display: flex;
			flex-direction: column;
		}
		&__sticker {
			position: absolute;
			right: 0rem;
			display: none;
		}
		&__title {
			flex-grow: 1;
		}
		&__titleInner {
			align-items: center;
			display: flex;
		}

		@media @mq[dt] {
			&__sticker {
				top: 5.75rem;
				width: 218px;
				height: 218px;
			}
			&__title {
				.grid;
				.grid--colGap;
				.grid--cols-12;
			}
			&__titleInner {
				grid-column: 2 / span 8;
			}
		}
		@media @mq[md] {
			&__sticker {
				top: 2rem;
				width: 122px;
				height: 122px;
			}
			&__title {
				display: flex;
			}
		}
	}
	.ZaPageHeader { // styling
		&__brand {
			padding-top: 2.6rem;
			line-height: 1.06em;
			font-weight: bold;

			a { color: inherit; }
		}
		&__sticker {
			background-image: url('../assets/imgs/alles-wird-gut-sticker.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
		&__title {
			color: #2A4960;
		}
		&__titleInner {}

		&--type-home {
			background: rgb(252,223,169);
			background: linear-gradient(135deg, rgba(252,223,169,1) 0%, rgba(255,205,167,1) 100%);
		}
		&--type-home &__inner {
			height: 100vh;
		}
		&--type-home &__sticker {
			display: block;
		}

		@media @mq[dt] {
			&__brand {
				font-size: 36px;
			}
			&__title {
				padding-bottom: 2em;
				font-size: 74px;
				line-height: 1.2em;
			}
			&--type-page &__title {
				padding-top: 1em;
			}
		}
		@media @mq[md] {
			&__brand {
				padding-top: 1.5rem;
				font-size: 24px;
			}
			&__title {
				padding-bottom: 2em;
				color: #2A4960;
				font-size: 56px;
				line-height: 1.3em;
			}
			&--type-page &__title {
				padding-top: 2em;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
