var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"App handleTextHierachieOutlining",attrs:{"lang":"de"}},[_c('MhDevGrid',{attrs:{"doLog":false,"showOnHosts":[
			'localhost',
			'zahnaerzte-luci-clausner.local',
			'dev.zahnaerzte-luci-clausner.de'
		],"show":_vm._.get( _vm.currentUser, 'display_name' ) ? true : undefined,"columns":12,"color":'cyan',"gridGap":'var(--app-content-gridGap)',"maxWidth":'var(--app-content-maxWidth)',"hPadding":'var(--app-content-hPadding)',"opacity":0.35,"toggleKey":'g',"forceVisibleOnMount":true}}),_c('MhRouterViewWrapper'),_c('MhDelegateLinks',{attrs:{"doLog":false}}),_c('MhTagsOutlining',{attrs:{"doLog":true,"color":'rgba( 255, 0, 175, 0.5 )',"selectors":[
			'[showborders4] .view'
		]}}),_c('MhHeadInfos',{attrs:{"doLog":false,"showDebugPanel":false,"ignoreViews":[
			'DevView' ]}}),_c('MhDevInfos',{attrs:{"doLog":false,"showOnHosts":[
			'localhost',
			'zahnaerzte-luci-clausner.local',
			'dev.zahnaerzte-luci-clausner.de' ],"show":_vm._.get( _vm.currentUser, 'display_name' ) ? true : undefined}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }