<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaBookingLink></ZaBookingLink>

	2019-06-08	init

-->

<template>
	<a class="ZaBookingLink" :class="elmClasses" :href="buttonLink" v-if="buttonLink && buttonText">
		<span class="ZaBookingLink__inner" v-html="buttonText"></span>
	</a>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'ZaBookingLink',
		components: {},
		mixins: [],
		props: {
			buttonText: {
				type     : [String],
				default  : '',
				required : false,
			},
			buttonLink: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaBookingLink { // debug
		[showBorders2] & {}
	}
	.ZaBookingLink { // vars
	}
	.ZaBookingLink { // layout
		position: fixed;
		right: 1em;
		bottom: 1em;
		z-index: 10;
	}
	.ZaBookingLink { // styling
		transition: all 0.1s ease;
		padding: 0.65em 1em;
		border-radius: 1.5em;
		font-weight: bold;
		color: white;
		display: inline-flex;

		background-color: #E48900;
		&:hover { background-color: darken( #E48900, 5 ); }

		@media @mq[dt] {
			font-size: 30px; line-height: 1.2em;
		}
		@media @mq[md] {
			font-size: 22px; line-height: 1.2em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
