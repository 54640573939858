<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaAppFooter></ZaAppFooter>

	2021-12-15	init

-->

<template>
	<div class="ZaAppFooter contentModule hSpace hSpace--app" :class="elmClasses" data-debug-label="ZaAppFooter">
		<div class="ZaAppFooter__inner">
			<h6 class="ZaAppFooter__brand">
				<a href="/">
					<strong>
						Zahnärzte <br/>
						Luci + Clausner<br/>
					</strong>
				</a>
				<br/>
			</h6>
			<div class="ZaAppFooter__colums">
				<div class="ZaAppFooter__colum ZaAppFooter__colum--1 richText" v-html="colum1"></div>
				<div class="ZaAppFooter__colum ZaAppFooter__colum--2 richText" v-html="colum2"></div>
				<div class="ZaAppFooter__colum ZaAppFooter__colum--3 richText" v-html="colum3"></div>
			</div>
			<!--
			<pre name="$props">{{$props}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'ZaAppFooter',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			colum1: {
				type     : [String],
				default  : '',
				required : false,
			},
			colum2: {
				type     : [String],
				default  : '',
				required : false,
			},
			colum3: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaAppFooter { // debug
		[showBorders3] &__colum {
			outline: 1px dotted magenta;
		}
	}
	.ZaAppFooter { // vars
	}
	.ZaAppFooter { // layout
		&__inner {
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}
		@media @mq[dt] {
			&__colums {
				.grid;
				.grid--colGap;
				.grid--cols-12;
			}
			&__colum--1 { grid-column: 1 / span 5; }
			&__colum--2 { grid-column: 6 / span 4; }
			&__colum--3 { grid-column: 10 / span 3; }
		}
	}
	.ZaAppFooter { // styling
		background: var(--color-app-footer-background);
		color: var(--color-app-footer-color);

		&__colum { padding-bottom: 1.5em; }
		&__brand a { color: inherit; }

		@media @mq[dt] {
			padding-top: 3.25em;
			padding-bottom: 5rem;

			&__brand { font-size: 28px; line-height: 1.35em; }
		}
		@media @mq[md] {
			padding-top: 1.25em;
			padding-bottom: 5rem;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
