<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<ZaCoronaMessage></ZaCoronaMessage>

	2022-01-20	init

-->

<template>
	<div class="ZaCoronaMessage" :class="elmClasses" data-debug-label="ZaCoronaMessage">
		<div class="ZaCoronaMessage__inner">
			<div class="ZaCoronaMessage__titleWrapper" @click="toggleState = !toggleState">
				<h3 class="ZaCoronaMessage__title" v-html="title"></h3>
				<div class="ZaCoronaMessage__toggleIndicator toggleIndicator" :class="toggleState && $root.isBelowDt ? 'toggleIndicator--isActive' : 'toggleIndicator--isInactive'" role="button" >&nbsp;</div>
			</div>
			<div class="ZaCoronaMessage__text richText" v-show-slide="toggleState || !$root.isBelowDt" v-html="text"></div>
			<!--
			<pre name="$props">{{$props}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'ZaCoronaMessage',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {
				toggleState : false,
			}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.someFunction)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.ZaCoronaMessage { // debug
		[showBorders3] &__inner > * {
			outline: 1px dotted magenta;
		}
	}
	.ZaCoronaMessage { // vars
	}
	.ZaCoronaMessage { // layout
		&__inner {
			max-width: var(--app-content-maxWidth);
			margin: 0 auto;
		}

		&__titleWrapper { display: flex; }
		&__title { flex-grow: 1; }

		@media @mq[dt] {
			&__inner {
				.grid;
				.grid--colGap;
				.grid--cols-12;
			}
			&__titleWrapper { grid-column: 1 / span 5; }
			&__toggleIndicator { display: none; }
			&__text { grid-column: 7 / span 5; }
		}
	}
	.ZaCoronaMessage { // styling
		@media @mq[dt] {
			padding-top: 2rem;
			padding-bottom: 2rem;

			&__title { font-size: 46px; line-height: 1.2em; }
		}
		@media @mq[md] {
			background-color: #FDF5E8;

			&__title { font-weight: bold; }
			&__titleWrapper { padding: 1rem 0; cursor: pointer; }
			&__toggleIndicator { margin-top: 0.15em; }
			&__text > *:last-child { padding-bottom: 1.5em; }
		}
		/*
		h1 { font-size: 74px; line-height: 1.2em; }
		h3 { font-size: 28px; line-height: 1.35em; }
		ul, ol { padding-left: 1.65rem; }
		li { padding-left: 0.5rem; }
		*/
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
