<template>
	<MhRouterView class="PageView view" :isContentReady="isContentReady">

		<div class="view__inner">

			<ZaContentModules
				:contentModules="_.get( post, 'acf.contentModules' )"
			></ZaContentModules>

			<!--
			<pre name="title" maxheight>{{_.get( post, 'title.rendered' )}}</pre>
			<pre name="post" maxheight>{{post}}</pre>
			-->

			<ZaAppFooter
				:colum1="_.get( app.acfOptions, 'appFooter__colum--1' )"
				:colum2="_.get( app.acfOptions, 'appFooter__colum--2' )"
				:colum3="_.get( app.acfOptions, 'appFooter__colum--3' )"
			></ZaAppFooter>

		</div>

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v3/MhRouterView.vue'
	import ZaContentModules from '@/components/ZaContentModules.vue'
	import ZaAppFooter from '@/components/ZaAppFooter.vue'

	export default {
		name: 'HomeView',
		components: {
			MhRouterView,
			ZaContentModules,
			ZaAppFooter,
		},
		mixins: [ RestHandler ],
		directives: {},
		props: {},
		data(){
			return {
				isContentReady: false,
				post: undefined,
			}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push('new_class')

				return classes
			},
		},
		methods: {
			fetchAll( finalCallback ){
				let thingsToLoad = 1

				const runCallback = ()=>{
					--thingsToLoad

					//console.log( this.$options.name, '• fetchAll() runCallback()', thingsToLoad)

					if( !thingsToLoad && this._.isFunction( finalCallback ) ) finalCallback()
				}

				const fetchParams = { postIds : this.homepageId }

				// fetch page
				this.restHandler__fetch({
					action : 'GET',
					route : '/wp-json/mh/v1/frontpage',
					params : fetchParams,
					callback : (response) => {
						this.post = response.data.result[0]

						runCallback()
					},
				})
			},
		},
		mounted() {
			this.fetchAll(()=>{
				this.isContentReady = true
			})
		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.HomeView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
